import './App.css';
// import {
//   BrowserRouter as Router,
//   Route, Switch
// } from "react-router-dom";
import IconSmall from './components/IconSmall';
import { Provider } from './contexts/globalContext';
import RequirePhone from './screens/RequirePhone';
import WindowsChat from './screens/WindowsChat';

function AppImpl() {
  return (
    <>
      <div className="chat-bot-ftech">
        <WindowsChat />
        <RequirePhone />
        <IconSmall/>
      </div>
    </>
  );
}


const App = (props) => ( 
  <Provider >
     <AppImpl {...props} />
  </Provider >
)
                                                                      
export default App

