import getDomainBotChat from "../ultils/api";
import { postAsync } from "../ultils/request";

export async function getAnswerBot(options) {
    let user_id = options?.user_id || options?.data?.meta?.user_id
    let data = options?.data
    let url = getDomainBotChat() + "/rocketchat/send_message/"+user_id;
    const response = await postAsync(url, data);
    let result = response?.data
    // if (result?.status !== 0) toastError(result?.message || result?.msg || "Xảy ra lỗi khi tải danh sách game")
    return result || {};
}