import React from 'react'
import './style.css'
import { listQuestionSuggest } from '../../constants/listQuestion'
import { useContexts } from '../../contexts/messengerContext'
export default function MenuSuggest({onClose}) {
    const { addMessenger } = useContexts();
    const handleClick = (question)=>{
        addMessenger({
            self: true,
            text: question
        })
        onClose && onClose()
    }
    return (
        <div className="menu-suggest px-6 py-4">
            {
                listQuestionSuggest.map((question, index) => {
                    return (
                        <div className="item-question-suggest pb-1 mb-1" key={`item-question-suggest-${index}`}>
                            <p style={{ color: '#2F80ED' }}
                                className="text-sm font-medium cursor-pointer"
                                onClick= {()=>handleClick(question)}
                            >
                                {question}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}
