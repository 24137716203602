import React from 'react';
import { useContexts } from '../../../contexts/messengerContext';
import { iDate } from '../../../ultils/ultis';
import Normal from './Normal';
export default function QuestionSuggest({ text = "", _meta = {}, time }) {
    const listButtons = _meta?.buttons || [];
    const { addMessenger } = useContexts()

    // const [hiddenQuestionSuggest, setHiddenQuestionSuggest] = useState(false)

    const handleClickBtton = (text, payload) => {
        //console.log(`messengerInput`, messengerInput)
        if (text === "") return;
        addMessenger({
            self: true,
            text: text,
            payload: payload
        })
    }

    // useEffect(() => {
    //     !hiddenQuestionSuggest && setHiddenQuestionSuggest(true)
    // }, [listMessenger.length])
    return (
        <>
            {text && <Normal text={text} showTime={false} />}

            {
                listButtons?.length > 0
                // &&
                // !hiddenQuestionSuggest
                &&
                <div className="flex flex-col ml-2" >
                    {
                        listButtons.map((item, index) => (
                            <div key={`question-suggest-${index}`} className="flex" onClick={() => { handleClickBtton(item?.title, item?.payload) }} >
                                <div className="bot-question-suggest mr-2 mt-1">
                                    {item?.title}
                                </div>
                            </div>
                        ))
                    }
                </div>

            }



            {time && <p className="time-messenger">{iDate(time, " {h}:{m}")}</p>}


        </>
    )
}
