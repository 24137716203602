import React, { useState } from 'react'
import ReactStars from "react-rating-stars-component";
import { TEXT_RATING_LEVEL } from '../../../constants/rating';
import { useContexts } from '../../../contexts/messengerContext';

export default function RatingDetail({ onSubmit }) {
    const {handleClosePopup } = useContexts()
    const [star, setStar] = useState(0);
    const [comment, setComment] = useState("");
    // const [err, setError] = useState(false)

    const onChangeStar = (value) => {
        setStar(value);
    }

    const handleChangeComment = (e) => {
        let value = e.target.value;
        setComment(value);
    }

    const handleSubmit = () => {
        // if(star===0) {
        //     setError("Vui lòng chọn điểm đánh giá");
        //     return
        // }
        // if (comment.length === 0) {
        //     setError("Vui lòng nhập comment");
        //     return 
        // }

        onSubmit && onSubmit({ star, comment })
    }
    return (
        <>
            <div className="popup-rating absolute top-0 right-0" onClick={handleClosePopup}>
            </div>
            <div className="popup-rating-content absolute">
                <div className="flex flex-col items-center  pt-3 ">
                    <p className="text-sm font-medium mb-2">
                        Gửi đánh giá
                    </p>

                    <div>
                        <ReactStars
                            className="react-star"
                            value={0}
                            count={5}
                            size={25}
                            ullIcon={<i className="fa fa-star mx-1"></i>}
                            activeColor="#ffd700"
                            onChange={onChangeStar}
                        />
                    </div>

                    <p className="font-medium text-xs my-2">
                        {star > 0 && TEXT_RATING_LEVEL[star - 1]}
                    </p>

                    <div className="rating-comment mb-3 mt-1">
                        <textarea
                            value={comment}
                            onChange={handleChangeComment}
                            rows={4}
                            placeholder="Vui lòng đưa ra đề xuất của bạn, để tính năng cải thiện tốt hơn."

                        />
                        {/* {
                            true && <p className="mt-1 text-xs font-normal text-red-500">
                                {err}
                            </p>
                        } */}
                    </div>

                    <div className="send-text flex items-center justify-center w-full py-2">
                        <p className="text-sm font-medium cursor-pointer" onClick={handleSubmit}>Gửi</p>
                    </div>
                </div>
            </div>
        </>
    )
}
