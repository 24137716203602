import React from 'react'
import Slider from 'react-slick';
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { X } from 'react-feather';
export default function SlideImage({ index = 0, data = [], handleClose }) {

    const settings = {
        customPaging: function (i) {
            return (
                <div className="custom-paging-slick-slide">
                    <img alt="ảnh " src={data[i]} />
                </div>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: index
    };

    const handleCloseSlide = (e) => {
        if (e?.target?.tagName === "IMG") return;
        handleClose && handleClose()
    }
    return (
        <div className="slick-slide-image">
            <div className="icon-close-chat mr-4" onClick={handleCloseSlide} >
                <X size={20} color="#fff" fill="#fff" layout="none" />
            </div>
            <Slider {...settings}>
                {data?.map((imgUrl, index) => {
                    return (
                        <div key={imgUrl || index} style={{ maxHeight: '80vh' }} className="slick-slide-item" onClick={handleCloseSlide}>
                            <img alt="ảnh " className=' position-relative' src={imgUrl || "https://minio.dev.ftech.ai/va-fetch-v1.2.0-648c0c98/images/offices/hanoi-1.jpg"} />
                        </div>
                    )
                })}


            </Slider>
        </div>
    )
}
