const TYPE_BOT_MESSAGE = {
    NORMAL: "NORMAL",
    RATING: "RATING",
    SUGGEST : "SUGGEST",
    AUTO: "AUTO",
    AGREE_SUGGEST : "AGREE_SUGGEST",
    TYPING : "TYPING",

}

export default TYPE_BOT_MESSAGE