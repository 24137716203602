import React from 'react'
import { iDate } from '../../../ultils/ultis'
import './style.css'
export default function SelfChat({text = "Dự án của bên mình gồm những mảng nào?", time}) {
    return (
        <div className="self-chat flex flex-col w-full items-end">
            <div className="self-chat-content">
               {text}
            </div>
            <p className="time-messenger">{iDate(time," {h}:{m}")}</p>
        </div>
    )
}
