export const listQuestionSuggest = [
    'Đánh giá Timi',
    'Công ty có tuyển remote không?',
    'Ftech AI đóng bảo hiểm xã hội không?',
    'Phỏng vấn có bài test không?',
    'Chế độ thưởng bên mình ra sao?',
    'Danh mục chính'
]

export const listAutoQuestionSuggest = [
    {
        question:`Chào bạn. Mình là trợ lý ảo Ftech.ai. Mình có thể giúp gì cho bạn không?`,
        // question:`Title\n\t+ Type 1\n\t+ Type 2`,
        answer: `TIMI được tạo ra nhằm thay thế và hỗ trợ nhân viên Hành chính nhân sự các hoạt động:
        - Trò chuyện như một người bạn tâm giao;
        - Giới thiệu và giải đáp các thắc mắc về công ty và phỏng vấn...
        Ngoài ra TIMI còn có thể trả lời các câu hỏi liên quan đến bản thân mình nữa ạ.`
    },
    {
        question:"Bạn muốn biết Ftech bao nhiêu nhân sự không ?",
        answer: "Tính đến nay, FTECH quy tụ gần 400 nhân viên, là đội ngũ kỹ sư, chuyên gia hàng đầu về nghiên cứu và xây dựng các giải pháp công nghệ."
    },
    {
        question:"Bạn muốn biết trang Facebook của ftech chứ?",
        answer: "Mình gửi bạn trang facebook của Ftech nhé: https://www.facebook.com/FTECHAI/"
    },
    {
        question:"Bạn có muốn biết thời gian thử việc ở FTECH không?",
        answer: "Trước khi trở thành nhân viên chính thức, bạn sẽ có cơ hội thử việc 2 tháng tại FTECH."
    },
    {
        question:"Bạn quan tâm đến chế độ thưởng ở Ftech chứ?",
        answer: "FTech AI có chế độ thưởng Tết, thưởng quý, thưởng nóng tùy theo đóng góp của mỗi cá nhân ạ."
    },
    {
        question:"Bạn có muốn biết thời gian làm việc ở FTECH chứ?",
        answer: "FTECH có thời gian làm việc 8h30 - 17h30, từ thứ 2 - thứ 6, và nghỉ thứ 7 và chủ nhật nha bạn!"
    },
    {
        question:"FTECH hiện đang phát triển rất nhiều lĩnh vực, bạn có muốn biết thêm không?",
        answer: "Chúng mình có rất nhiều dự án về Game, AI và các phần mềm thông minh ạ."
    },
    {
        question:"Nói chuyện với mình để biết thêm thông tin BHXH ở Ftech nhé!",
        answer: "Công ty đóng đầy đủ các loại bảo hiểm cho nhân viên theo mức lương cơ bản, phù hợp với quy định của Nhà nước. Áp dụng với nhân viên ký HĐLĐ chính thức."
    },
    {
        question:"Bạn quan tâm đến chế độ OT hãy nói chuyện với mình nhé.",
        answer: "FTECH không OT thường xuyên đâu ạ. Tuy nhiên, tuỳ vào yêu cầu của dự án, công ty sẽ bố trí nhân sự (fulltime/CTV/TTS) và thời gian OT phù hợp ạ. Mức lương OT cũng sẽ được công ty trả theo quy định của Luật Lao Động ạ."
    },
    {

        question:"Nói chuyện với mình để biết thêm mức lương khi thử việc nhé.",
        answer: "Mức lương thử việc của FTECH AI theo luật Lao động bằng 85% mức lương chính thức."
    },
    {

        question:"Bạn có muốn biết thêm về môi trường làm việc tại FTECH không?",
        answer: "Làm việc tại FTECH bạn sẽ tham gia các sự kiện lớn - Tiệc sinh nhật hàng tháng cho CBNV, và các ngày lễ trong năm được tổ chức với nhiều hoạt động và phần quà hấp dẫn. Cơ hội được đăng ký tham gia các CLB ngoài giờ, tham gia các giải thi đấu bóng đá, thi đấu game..."
    },
    {

        question:"Bạn có muốn biết thêm về các hoạt động ngoại khóa, team building tại FTECH không nhỉ?",
        answer: "FTECH tổ chức các buổi Team Building, cắm trại, du lịch...tăng cường sự kết nối giữa CBNV toàn công ty, góp phần tái tạo và duy trì nguồn năng lượng tích cực trong công việc."
    },
    
]

export function getRandomQuestionSuggest() {
    let min = 0;
    let max = listAutoQuestionSuggest.length - 1;
    let randomIndex = Math.floor(Math.random() * (max - min + 1)) + min
    return listAutoQuestionSuggest[randomIndex]
}