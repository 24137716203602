import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import emoji from "emoji-dictionary";

export default function Markdown({children}){
    return (
        <ReactMarkdown
        // allowedElements = {["<pre></pre>"]}
        remarkPlugins={[remarkGfm]}
        linkTarget="_blank"
        components={{
            // a: ({ node, children, ...props }) => <a  {...props}  target="_blank">{children}</a>,
            em: ({ node, ...props }) => <strong   {...props} />,
            strong: ({ node, ...props }) => <em  {...props} />,
        }}
    >
        {children
        .replace(/\t/g, `&emsp;`)
        .replace(/:\w+:/gi, (name) => emoji.getUnicode(name))
        }
    </ReactMarkdown>
    )
}
