/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    createContext, useCallback, useContext, useEffect, useMemo, useRef, useState
} from "react";
import { listAutoQuestionSuggest } from "../constants/listQuestion";
import { TEXT_REQUIRE_RATING, TURN_FINISHED_RATING } from "../constants/rating";
import { TIME_DELAY_ANSER, TIME_MESSAGE_AUTO } from "../constants/time";
import TYPE_BOT_MESSAGE from "../constants/typeMessenger";
import useTogglePopup from "../hooks/useTogglePopup";
import { getAnswerBot } from "../services/messengerService";
import { getUserId } from "../ultils/genUuid";
import { shuffle } from "../ultils/ultis";

export const MessengerContext = createContext();
export const useContexts = () => useContext(MessengerContext);

export const Provider = ({ children }) => {
    const listQuestionRandom = [listAutoQuestionSuggest[0], ...shuffle(listAutoQuestionSuggest.slice(1))]
    const [rated, setRated] = useState(false)
    const [listMessenger, setListMessenger] = useState([])
    const [userId, setUserId] = useState(null)
    // const [questionAuto, setQuestionAuto] = useState(listAutoQuestionSuggest[0])
    // const [stopAutoQuestion, setStopAutoQuestion] = useState(true)
    // const { questionAuto, setQuestionAuto } = useGlobalContexts()
    const [questionAuto, setQuestionAuto] = useState(listQuestionRandom[0])  // câu hỏi chủ động bên trong độc lập với bên ngoài bong bóng
    const { show: showPopup, handleClose: handleClosePopup, togglePopup: togglePopupPopup } = useTogglePopup();
    const { show: showTyping, handleClose: closeTyping, togglePopup: toggleTyping } = useTogglePopup();
    const [uiPopup, setUiPopup] = useState(null)


    const turnFinished = useRef(0)
    const timeOutQuestionAuto = useRef(null)
    const turnChangeQuestion = useRef(1)
    useEffect(() => {
        let user_id = getUserId()
        setUserId(user_id)

    }, [])




    useEffect(() => {
        addMessenger({
            type: TYPE_BOT_MESSAGE.AUTO,
            text: questionAuto?.question,
            answer: questionAuto?.answer,
            _meta: {
                buttons: [
                    {
                        title: "oke",
                        payload: "oke"
                    }
                ]
            }
        })
    }, [questionAuto])




    const addMessenger = (messenger) => {
        let newMes = { ...messenger, time: new Date() };
        // let newListMes = [...listMessenger];
        // newListMes.push(newMes);
        // //console.log(`listMessenger`, listMessenger)
        // //console.log(`newListMes`, newListMes)
        // setListMessenger(newListMes)
        setListMessenger(list => {
            let listClone = list
            if (listClone.length > 0 && listClone[listClone?.length - 1]?._meta?.buttons?.length > 0) {
                listClone[listClone?.length - 1]._meta.buttons = []   // xoá suggest sau khi có thao tác tiếp
            }
            return [...listClone, newMes]
        })

    }


    const getAnswer = useCallback(
        async (messenger) => {
            if (messenger.text === TEXT_REQUIRE_RATING) {
                requireRating();
                return
            }
            let newMes = { ...messenger, time: new Date() };

            if (!newMes.meta) newMes.meta = {}
            newMes.meta.user_id = userId;

            if (newMes.payload) {
                newMes.text = newMes.payload
                delete newMes.payload;

            }

            if (newMes.self) delete newMes.self;

            toggleTyping() // hiện đang gõ
            let startTime = performance.now()
            let answer = await getAnswerBot({ data: newMes });
            let endTime = performance.now()
            let remainTime = TIME_DELAY_ANSER - (endTime - startTime)

            // let answer = {
            //     text: "oke bạn",
            //     meta:{}
            // };

            if (answer) {

                if (remainTime > 0) {
                    setTimeout(() => {
                        closeTyping();
                        if(answer?.text === `/request_rating_conversation\n`){
                            // check điều kiện là triger đánh giá từ bot
                            requireRating()
                        }
                        else{
                            addMessenger(answer);
                        }
                        
                        turnFinished.current = turnFinished.current + 1
                        // console.log(`turnFinished.current`, turnFinished.current)
                        if (turnFinished.current === TURN_FINISHED_RATING && !rated) {
                            requireRating()
                        }
                    }, remainTime)
                }
                else {
                    addMessenger(answer);
                    closeTyping()
                }

            }



        },
        [userId, rated, turnFinished]
    )

    const requireRating = () => {
        let newMes = {
            time: new Date(),
            type: TYPE_BOT_MESSAGE.RATING,
            text: "Bạn đánh giá tính năng trợ lý ảo của Ftech.ai thế nào ?",
            refresh: true
        }
        addMessenger(newMes)
    }


    useEffect(() => {
        if (listMessenger.length === 0) {
            return;
        }

        let lastMes = listMessenger[listMessenger.length - 1];

        if (lastMes.type === TYPE_BOT_MESSAGE.AGREE_SUGGEST) return;
        if (lastMes.self) {
            getAnswer(lastMes)
        }


        if (timeOutQuestionAuto.current) clearTimeout(timeOutQuestionAuto.current)
        if (lastMes?.type !== TYPE_BOT_MESSAGE.AUTO)
            timeOutQuestionAuto.current = setTimeout(() => {
                let index = turnChangeQuestion.current % (listQuestionRandom.length)
                let randomQuestion = listQuestionRandom[index];
                setQuestionAuto(randomQuestion)
                turnChangeQuestion.current = turnChangeQuestion.current + 1;

            }, TIME_MESSAGE_AUTO)

    }, [listMessenger.length])




    const value = useMemo(
        () => ({
            rated, setRated,
            listMessenger, addMessenger,
            showPopup, handleClosePopup, togglePopupPopup,
            uiPopup, setUiPopup, showTyping, closeTyping,
        }),
        [rated, listMessenger, showPopup, uiPopup, showTyping]
    );



    return (
        <MessengerContext.Provider value={value}>
            {children}
        </MessengerContext.Provider>
    );
};
