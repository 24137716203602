import getDomainBotChat from "../ultils/api";
import { getUserId } from "../ultils/genUuid";
import { postAsync } from "../ultils/request";

export async function likeMessage(message) {

    let user_id = message?.meta?.user_id
    if(!user_id) user_id = getUserId()
    let url = getDomainBotChat() + "/rocketchat/send_message/"+user_id;
    const response = await postAsync(url, message);
    let result = response?.data
    // if (result?.status !== 0) toastError(result?.message || result?.msg || "Xảy ra lỗi khi tải danh sách game")
    return result || {};
}

export async function ratingChat(message) {

    let user_id = message?.meta?.user_id
    if(!user_id) user_id = getUserId()
    let url = getDomainBotChat() + "/rocketchat/send_message/"+user_id;
    const response = await postAsync(url, message);
    let result = response?.data
    // if (result?.status !== 0) toastError(result?.message || result?.msg || "Xảy ra lỗi khi tải danh sách game")
    return result || {};
}