import classNames from 'classnames'
import React, { useState } from 'react'
import { ThumbsDown, ThumbsUp } from 'react-feather'
import { useContexts } from '../../../contexts/messengerContext'
import { likeMessage } from '../../../services/ratingService'
import { getUserId } from '../../../ultils/genUuid'
import { iDate } from '../../../ultils/ultis'
import Markdown from '../../Markdown'
import SlideImage from '../../SlideImage'

export default function Normal({ text = "xin lỗi Timi hiện tại không thể trả lời câu hỏi này", _meta: meta, time, showTime = true, isShowIcon = true, isShowLike = true, like = "none" }) {
    const [isLike, setIsLiked] = useState(like)  // like hay ko
    const [clicked, setClicked] = useState(false)
    const {handleClosePopup, togglePopupPopup, setUiPopup } = useContexts()
    const user_id = getUserId()
    //console.log(`user_id`, user_id)
    const liked = !(isLike === "none")   // đã like hay chưa
    const changeLike = (like) => {
        if (liked) return;
        setIsLiked(like);
        let textPayload = `/rating_message{'value':"${like ? "like" : "dislike"}"}`;
        likeMessage({
            text: textPayload,
            meta: {
                user_id,
                message: text
            }
        })

    }

    let listImage = [];
    if (meta?.image) {
        if (Array.isArray(meta?.image))
            listImage = meta?.image
        else if (typeof (meta?.image) == "string")
            listImage = [meta?.image]
    }

    //console.log(`time`, time)
    //console.log(`text`, text)

    const handleShowListImage = (index=0)=>{
        if(listImage?.length === 0 ) return;
        setUiPopup(<SlideImage data={listImage} index={index} handleClose={handleClosePopup}/>)
        togglePopupPopup()
    }
    return (
        <div className="flex flex-col ml-2" onClick={() => { setClicked(true) }}>
            {
                text
                &&
                <div className={classNames("flex order-0", { "order-2": meta?.image_first })}>


                    <div className="bot-messenger-content mr-2">
                        {/* {text} */}
                        <Markdown>
                            {text}
                        </Markdown>
                    </div>

                    {
                        clicked
                        &&
                        isShowLike
                        &&
                        <div className="flex items-center">
                            {
                                (!isLike && liked) ? null
                                    : <ThumbsUp
                                        size={(isLike && liked) ? 24 : 19}
                                        fill={(isLike && liked) ? "#000" : "none"}
                                        color={(isLike && liked) ? "#fff" : "#000"}
                                        className={classNames("mr-1", { " cursor-pointer": !liked })}
                                        onClick={() => changeLike(true)}
                                    />

                            }

                            {
                                (isLike && liked) ? null
                                    : <ThumbsDown
                                        size={(!isLike) ? 24 : 19}
                                        fill={(!isLike) ? "#000" : "none"}
                                        color={(!isLike) ? "#fff" : "#000"}
                                        onClick={() => changeLike(false)}
                                        className={classNames("mr-1", { " cursor-pointer": !liked })}

                                    />

                            }


                        </div>
                    }
                </div>
            }

            {
                listImage.length > 0
                &&
                <div className="list-image flex flex-wrap mt-1 order-1">
                    {listImage.map((image, index) => {
                        return (
                            <div key={image||index} className="wrap-image mx-1 my-1 rounded overflow-hidden cursor-pointer" onClick={()=>handleShowListImage(index)}>
                                <img src={image} alt={`ảnh ${index+1}`} width="auto" height="auto" style={{ maxWidth: Math.max(240 / listImage?.length, 240 / 3), objectFit: "cover" }} />
                            </div>
                        )
                    })
                    }
                </div>
            }
            {showTime && <p className="time-messenger order-3">{iDate(time, " {h}:{m}")}</p>}
        </div>
    )
}
