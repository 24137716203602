import classNames from 'classnames';
import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { useContexts } from '../../../contexts/messengerContext';
import { ratingChat } from '../../../services/ratingService';
import { getUserId } from '../../../ultils/genUuid';
import { iDate } from '../../../ultils/ultis';
import RatingDetail from './RatingDetail';

export default function RatingMessenger({ time, refresh=false}) {
    // const { show, handleClose, togglePopup, setUiPopup } = useTogglePopup();
    const {handleClosePopup, togglePopupPopup, setUiPopup } = useContexts()

    // const [rated, setRated] = useState(false);
    const [showTextSuccess, setShowTextSuccess] = useState(false);
    const [rateValue, changeRateValue ] = useState(0);

    const [timeFinished, setTimeFinished] = useState(null)

    const {setRated} = useContexts()

    const rated = rateValue > 0 ? true : false
    //console.log(`rateValue`, rateValue)
    const user_id = getUserId()

    const handleSubmitRating = (prarams = {}) => {
        let { star, comment } = prarams
        //console.log(`star`, star)
        handleClosePopup()
        // gửi api
        setShowTextSuccess(true)
        setTimeFinished(new Date())
        changeRateValue(star)
        setRated(true)

        if(comment ==="" && star===0) return ;

        if(star===0) star=""

        let textPayload = `/rating_conversation{'value':"${star}"}`;
        ratingChat({
            text: textPayload,
            meta:{
                user_id,
                message: comment
            }
        })
    }

    const hadleShowRatingDetail = () => {
        //check đã đánh giá chưa
        if (rated) return;
        setUiPopup(<RatingDetail onSubmit={handleSubmitRating} />)
        togglePopupPopup()
    }
    return (
        <>
            <div className={classNames("flex flex-col ml-2", { "cursor-pointer": !rated })} onClick={hadleShowRatingDetail}>
                <div className="flex">
                    <div className="bot-messenger-rating mr-2">
                        Bạn đánh giá tính năng trợ lý ảo của Ftech.ai thế nào ?
                    </div>
                </div>

                <div className="block-messenger-rating flex flex-col items-center my-4 pt-3 pb-5 px-10">
                    <p className="text-sm font-medium mb-2">
                        Bạn thấy Timi có hữu ích không ?
                    </p>

                    <div>
                        {rated && <ReactStars
                            className="react-star"
                            edit={false}
                            value={rateValue}
                            count={5}
                            size={28}
                            ullIcon={<i className="fa fa-star mx-1"></i>}
                            activeColor="#ffd700"

                        />}
                        {
                            !rated &&
                            <ReactStars
                                className="react-star"
                                edit={false}
                                value={0}
                                count={5}
                                size={28}
                                ullIcon={<i className="fa fa-star mx-1"></i>}
                                activeColor="#ffd700"

                            />
                        }

                    </div>
                </div>
                {!timeFinished && <p className="time-messenger">{iDate(time, " {h}:{m}")}</p>}

                {
                    rated
                    &&
                    showTextSuccess
                    &&
                    <>
                        <div className="flex">
                            <div className="bot-messenger-rating mr-2">
                                Cám ơn bạn đã gửi đánh giá. Tiếp tục
                                trò chuyện với mình để hiểu hơn về. Ftech nhé !
                            </div>
                        </div>
                        <p className="time-messenger">{iDate(timeFinished, " {h}:{m}")}</p>
                    </>
                }



            </div>
            {/* {
                show
                &&
                !rated
                &&
                <RatingDetail onSubmit={handleSubmitRating} />
            } */}

        </>
    )
}
