import React, { useState } from 'react'
import TYPE_BOT_MESSAGE from '../../../constants/typeMessenger'
import { useContexts } from '../../../contexts/messengerContext'
import { iDate } from '../../../ultils/ultis'
import Markdown from '../../Markdown'


export default function MessengerAuto({ text = "tin nhắn tự động", _meta = {}, answer = "trả lời tự động", time }) {
    const { addMessenger } = useContexts()
    const [clicked, setClicked] = useState(false)
    const listButtons = _meta?.buttons || [];
    const handleGetAnser = (answer) => {
        if (clicked || !answer) return;
        setClicked(true)

        addMessenger({
            self: true,
            text: "oke",
            type: TYPE_BOT_MESSAGE.AGREE_SUGGEST
        })

        addMessenger({
            self: false,
            text: answer
        })
    }
    return (
        <div className="flex flex-col ml-2" onClick={() => { }}>
            <div className="flex items-center">
                <div className="bot-messenger-content mr-2">
                    {/* {text} */}
                    <Markdown>
                        {text}
                    </Markdown>
                </div>

            </div>
            {
                answer
                &&
                listButtons?.length > 0
                &&
                <div className="flex flex-col ml-2" >
                    {
                        listButtons.map((item, index) => (
                            <div key={`question-suggest-${index}`} className="flex" onClick={() => { handleGetAnser(answer) }} >
                                <div className="bot-question-suggest mr-2 mt-1">
                                    {item?.title}
                                </div>
                            </div>
                        ))
                    }
                </div>


            }


            <p className="time-messenger">{iDate(time, " {h}:{m}")}</p>
        </div>
    )
}
