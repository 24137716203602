/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    createContext, useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { listAutoQuestionSuggest } from "../constants/listQuestion";
import STATUS_CHAT from "../constants/statusChat";
import { TIME_MESSAGE_AUTO } from "../constants/time";
import { shuffle } from "../ultils/ultis";

export const GlobalContext = createContext();
export const useContexts = () => useContext(GlobalContext);

export const Provider = ({ children }) => {

    const listQuestionRandom = shuffle(listAutoQuestionSuggest)

    const [status, setStatus] = useState(STATUS_CHAT.BEFOR_CHAT)
    const [requiredPhone, setRequiredPhone] = useState(false)
    const timeOutQuestionAuto = useRef(null)
    const turnChangeQuestion = useRef(1)

    const [questionAuto, setQuestionAuto] = useState(listQuestionRandom[0])

    // useEffect(() => {
    //     let user_id = sessionStorage.getItem("user_id");
    //     if(user_id) setRequiredPhone(true)

    // }, [])

    useEffect(() => {
        if(status !== STATUS_CHAT.BEFOR_CHAT) return 
        if (timeOutQuestionAuto.current) clearTimeout(timeOutQuestionAuto.current)
        if (turnChangeQuestion.current <= 5) {
            timeOutQuestionAuto.current = setTimeout(() => {
                let randomQuestion = listQuestionRandom[turnChangeQuestion.current];
                setQuestionAuto(randomQuestion)
                turnChangeQuestion.current = turnChangeQuestion.current+1;
            }, TIME_MESSAGE_AUTO)
        }

    }, [questionAuto, status])



    const value = useMemo(
        () => ({
            status, setStatus,
            requiredPhone, setRequiredPhone,
            questionAuto, setQuestionAuto
        }),
        [status, requiredPhone, questionAuto]
    );

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};
