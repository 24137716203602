import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Menu, Send, X } from 'react-feather'
import IconLogo from '../../assets/icons/timi.png'
import MenuSuggest from '../../components/MenuSuggest'
import BotMessenger from '../../components/Messenger/BotMessenger/index'
import SelfChat from '../../components/Messenger/SelfMesseger'
import STATUS_CHAT from '../../constants/statusChat'
import TYPE_BOT_MESSAGE from '../../constants/typeMessenger'
import { useContexts as useGolbalContexts } from '../../contexts/globalContext'
import { Provider, useContexts } from '../../contexts/messengerContext'
import { useTogglePopup } from '../../hooks'
import './style.css'

function WindowsChatImpl() {
    const { status, setStatus } = useGolbalContexts();
    const { show, togglePopup, handleClose } = useTogglePopup()
    const { listMessenger, addMessenger, showPopup, uiPopup, showTyping } = useContexts()
    const [messengerInput, setMessengerInput] = useState("")


    const listChatRef = useRef()

    const scrollToBot = (element) => {
        element.scroll({
            top: element.scrollHeight,
            behavior: 'smooth'
        })
    }

    useEffect(() => {

        scrollToBot(listChatRef.current)
        // if (listChatRef.current) {
        //     const { scrollHeight, scrollTop, clientHeight } = listChatRef.current
        //     if (scrollHeight - scrollTop - clientHeight < 200)
        //         scrollToBot(listChatRef.current)
        // }
    }, [listMessenger?.length, showTyping])

    const handleSendMes = () => {
        //console.log(`messengerInput`, messengerInput)
        if (messengerInput === "") return;
        addMessenger({
            self: true,
            text: messengerInput
        })
        setMessengerInput("")
    }

    const handlenput = useCallback(
        (e) => {
            let value = e.target.value;
            setMessengerInput(value)
        },
        [],
    )

    const handleKeyDow = (e) => {
        if (e.keyCode === 13) {
            //console.log(`đã chạy vào trong`)
            handleSendMes();
            return
        }
    }

    // const handleSubmitRating = ()=>{
    //     togglePopupRating();
    // }

    const handleCloseMenu =()=>{
        show && handleClose()
    }
    return (
        <div 
            className={classNames('windows-chat flex flex-col', { hidden: !(status === STATUS_CHAT.CHATTING) })} 
            onClick= {handleCloseMenu}
        >

            {showPopup && uiPopup}

            <div className="top-bar-chat flex flex-row justify-between items-center">
                <div className="ml-2 mr-3">
                    <img alt="icon-medium" width="40px" height="auto" src={"https://cms.ftech.ai/uploads/timi_7fb1846e_b8180cbf4e.png"} style={{ borderRadius: "50%" }} />
                </div>
                <div className="flex-grow ">
                    <p className="m-0">TIMI</p>
                    <p className="m-0">Trợ lý FTECH.AI </p>
                </div>
                <div className="icon-close-chat mr-4" onClick={() => { setStatus(STATUS_CHAT.BEFOR_CHAT) }} >
                    <X size={20} color="#595959" fill="#F6F6F6" layout="none" />
                </div>
            </div>

            <div className="content-chat flex flex-col flex-grow px-2 py-3 relative" ref={listChatRef}>

                {
                    listMessenger.map((item, index) => {
                        return (
                            <div className="flex" key={index}>
                                {item?.self ? < SelfChat {...item} /> : <BotMessenger {...item} />}
                            </div>
                        )
                    })
                }

                {showTyping && <BotMessenger type={TYPE_BOT_MESSAGE.TYPING} />}

            </div>

            <div className="footer-chat flex flex-row items-center ">
                <div className="relative">
                    <div className="icon-menu pl-4 cursor-pointer "    onClick={togglePopup}>
                        <Menu size={20} />

                    </div>
                    {show && <MenuSuggest onClose={togglePopup} />}
                </div>

                <div className="input-chat flex-grow mx-4 my-4">
                    <input type="text" className="w-full" value={messengerInput} onChange={handlenput} onKeyDown={handleKeyDow} placeholder="Nhập tin nhắn tại đây..." />
                </div>

                <div className={classNames("icon-send-messenger pr-4", { 'cursor-pointer': messengerInput?.length > 0 })}>
                    <Send onClick={handleSendMes} size={20} color={messengerInput?.length === 0 ? "#d1d5db" : "#000"} />
                </div>
            </div>


        </div>
    )
}



const WindowsChat = (props) => (
    <Provider >
        <WindowsChatImpl {...props} />
    </Provider >
)

export default memo(WindowsChat)